/**************** COUNTRIES *********************/

export const ACTION_COUNTRY = "ACTION_COUNTRY";
export const CREATE_COUNTRY = "CREATE_COUNTRY";
export const ERROR_CREATE_COUNTRY = "ERROR_CREATE_COUNTRY";

export const ACTION_FETCH_COUNTRY = "ACTION_FETCH_COUNTRY";
export const FETCH_COUNTRY = "FETCH_COUNTRY";
export const ERROR_FETCH_COUNTRY = "ERROR_FETCH_COUNTRY";
export const CLEAR_COUNTRY = "CLEAR_COUNTRY";

export const UPDATE_COUNTRY = "UPDATE_COUNTRY";
export const ERROR_UPDATE_COUNTRY = "ERROR_UPDATE_COUNTRY";

export const CLEAR_ALERT_COUNTRY = "CLEAR_ALERT_COUNTRY";

export const FETCH_COUNTRIES = "FETCH_COUNTRIES";
export const ERROR_FETCH_COUNTRIES = "ERROR_FETCH_COUNTRIES";

export const DELETE_COUNTRY = "DELETE_COUNTRY";
export const ERROR_DELETE_COUNTRY = "ERROR_DELETE_COUNTRY";

export const ACTIVE_COUNTRY = "ACTIVE_COUNTRY";
export const ERROR_ACTIVE_COUNTRY = "ERROR_ACTIVE_COUNTRY";

export const SET_SELECTED_PAGE_SIZE = "SET_SELECTED_PAGE_SIZE_COUNTRY";
export const SET_SEARCH = "SET_SEARCH_COUNTRY";
export const SET_SEARCH_VALUE = "SET_SEARCH_VALUE_COUNTRY";
export const SET_CURRENT_PAGE = "SET_CURRENT_PAGE_COUNTRY";
export const SET_SORT = "SET_SORT_COUNTRY";


