import {
    ACTION_COUNTRY,
    CREATE_COUNTRY,
    ERROR_CREATE_COUNTRY,
    ACTION_FETCH_COUNTRY,
    FETCH_COUNTRY,
    ERROR_FETCH_COUNTRY,
    CLEAR_COUNTRY,
    UPDATE_COUNTRY,
    ERROR_UPDATE_COUNTRY,
    CLEAR_ALERT_COUNTRY,
    FETCH_COUNTRIES,
    ERROR_FETCH_COUNTRIES,
    SET_SELECTED_PAGE_SIZE,
    SET_SEARCH,
    SET_SEARCH_VALUE,
    SET_CURRENT_PAGE,
    SET_SORT,
    DELETE_COUNTRY,
    ERROR_DELETE_COUNTRY,
    ACTIVE_COUNTRY,
    ERROR_ACTIVE_COUNTRY
} from './types';



const INIT_STATE = {
    countries: {
        data: [],
        totalItemCount: 0
    },
    selectedPageSize: 5,
    search: "",
    searchValue: "",
    currentPage: 1,
    sort: [{ desc: false, id: "id" }],
    createCountry: '',
    updateCountry: '',
    activeCountry: '',
    deleteCountry: '',
    countryData: {
        edit: false,
        country_en: '',
        country_el: '',
        country_de: '',
        country_rs: '',
        country_bg: '',
        country_ru: '',
        iso: '',
        lang_iso: '',
        country_phone_code: ''
    },
    loadingBtn: false,
    loading: true,
    error: ''
};


export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case ACTION_COUNTRY:
            return { ...state, loadingBtn: true, error: '' };
        case CREATE_COUNTRY:
            return { ...state, loadingBtn: false, createCountry: action.payload, error: '' };
        case ERROR_CREATE_COUNTRY:
            return { ...state, loadingBtn: false, createCountry: '', error: action.payload.message };
        case ACTION_FETCH_COUNTRY:
            return { ...state, loading: true, loadingBtn: false, error: '' };
        case FETCH_COUNTRY:
            return { ...state, loading: false, countryData: action.payload, error: '' };
        case ERROR_FETCH_COUNTRY:
            return { ...state, loading: false, countryData: '', error: action.payload.message };
        case CLEAR_COUNTRY:
            return { ...state, loadingBtn: false, loading: false, countryData: INIT_STATE.countryData, error: '' };
        case UPDATE_COUNTRY:
            return { ...state, loadingBtn: false, updateCountry: action.payload, error: '' };
        case ERROR_UPDATE_COUNTRY:
            return { ...state, loadingBtn: false, updateCountry: '', error: action.payload.message };
        case CLEAR_ALERT_COUNTRY:
            return { ...state, updateCountry: '', createCountry: '', deleteCountry: '', activeCountry: '', error: '' };
        case FETCH_COUNTRIES:
            return { ...state, loading: false, countries: action.payload, error: '' };
        case ERROR_FETCH_COUNTRIES:
            return { ...state, loading: false, countries: INIT_STATE.countries, error: action.payload.message };
        case DELETE_COUNTRY:
            return { ...state, loading: false, deleteCountry: action.payload, error: '' };
        case ERROR_DELETE_COUNTRY:
            return { ...state, loading: false, deleteCountry: '', error: action.payload.message };
        case ACTIVE_COUNTRY:
            return { ...state, loading: false, activeCountry: action.payload, error: '' };
        case ERROR_ACTIVE_COUNTRY:
            return { ...state, loading: false, activeCountry: '', error: action.payload.message };

        //seting list heading
        case SET_SELECTED_PAGE_SIZE:
            return { ...state, selectedPageSize: action.payload, currentPage: 1 };
        case SET_SEARCH:
            return { ...state, search: action.payload, currentPage: 1 };
        case SET_SEARCH_VALUE:
            return { ...state, searchValue: action.payload };
        case SET_CURRENT_PAGE:
            return { ...state, currentPage: action.payload };
        case SET_SORT:
            return { ...state, sort: action.payload };
        default: return state;
    }
}
