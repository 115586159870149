/**************** SUBCATEGORIES *********************/

export const ACTION_SUBCATEGORY = "ACTION_SUBCATEGORY";
export const CREATE_SUBCATEGORY = "CREATE_SUBCATEGORY";
export const ERROR_CREATE_SUBCATEGORY = "ERROR_CREATE_SUBCATEGORY";

export const ACTION_FETCH_SUBCATEGORY = "ACTION_FETCH_SUBCATEGORY";
export const FETCH_SUBCATEGORY = "FETCH_SUBCATEGORY";
export const ERROR_FETCH_SUBCATEGORY = "ERROR_FETCH_SUBCATEGORY";
export const CLEAR_SUBCATEGORY = "CLEAR_SUBCATEGORY";

export const UPDATE_SUBCATEGORY = "UPDATE_SUBCATEGORY";
export const ERROR_UPDATE_SUBCATEGORY = "ERROR_UPDATE_SUBCATEGORY";

export const CLEAR_ALERT_SUBCATEGORY = "CLEAR_ALERT_SUBCATEGORY";

export const FETCH_SUBCATEGORIES = "FETCH_SUBCATEGORIES";
export const ERROR_FETCH_SUBCATEGORIES = "ERROR_FETCH_SUBCATEGORIES";

export const DELETE_SUBCATEGORY = "DELETE_SUBCATEGORY";
export const ERROR_DELETE_SUBCATEGORY = "ERROR_DELETE_SUBCATEGORY";

export const ACTIVE_SUBCATEGORY = "ACTIVE_SUBCATEGORY";
export const ERROR_ACTIVE_SUBCATEGORY = "ERROR_ACTIVE_SUBCATEGORY";

export const ACTION_DATA_FIELDS_SUBCATEGORY = "ACTION_DATA_FIELDS_SUBCATEGORY";
export const DATA_FIELDS_SUBCATEGORY = "DATA_FIELDS_SUBCATEGORY";
export const ERROR_DATA_FIELDS_SUBCATEGORY = "ERROR_DATA_FIELDS_SUBCATEGORY";

export const SET_SELECTED_PAGE_SIZE = "SET_SELECTED_PAGE_SIZE_SUBCATEGORY";
export const SET_SEARCH = "SET_SEARCH_SUBCATEGORY";
export const SET_SEARCH_VALUE = "SET_SEARCH_VALUE_SUBCATEGORY";
export const SET_CURRENT_PAGE = "SET_CURRENT_PAGE_SUBCATEGORY";
export const SET_SORT = "SET_SORT_SUBCATEGORY";


