/**************** TYPES *********************/

export const ACTION_TYPE = "ACTION_TYPE";
export const CREATE_TYPE = "CREATE_TYPE";
export const ERROR_CREATE_TYPE = "ERROR_CREATE_TYPE";

export const ACTION_FETCH_TYPE = "ACTION_FETCH_TYPE";
export const FETCH_TYPE = "FETCH_TYPE";
export const ERROR_FETCH_TYPE = "ERROR_FETCH_TYPE";
export const CLEAR_TYPE = "CLEAR_TYPE";

export const UPDATE_TYPE = "UPDATE_TYPE";
export const ERROR_UPDATE_TYPE = "ERROR_UPDATE_TYPE";

export const CLEAR_ALERT_TYPE = "CLEAR_ALERT_TYPE";

export const FETCH_TYPES = "FETCH_TYPES";
export const ERROR_FETCH_TYPES = "ERROR_FETCH_TYPES";

export const DELETE_TYPE = "DELETE_TYPE";
export const ERROR_DELETE_TYPE = "ERROR_DELETE_TYPE";

export const ACTIVE_TYPE = "ACTIVE_TYPE";
export const ERROR_ACTIVE_TYPE = "ERROR_ACTIVE_TYPE";

export const ACTION_DATA_FIELDS_TYPE = "ACTION_DATA_FIELDS_TYPE";
export const DATA_FIELDS_TYPE = "DATA_FIELDS_TYPE";
export const ERROR_DATA_FIELDS_TYPE = "ERROR_DATA_FIELDS_TYPE";

export const SET_SELECTED_PAGE_SIZE = "SET_SELECTED_PAGE_SIZE_TYPE";
export const SET_SEARCH = "SET_SEARCH_TYPE";
export const SET_SEARCH_VALUE = "SET_SEARCH_VALUE_TYPE";
export const SET_CURRENT_PAGE = "SET_CURRENT_PAGE_TYPE";
export const SET_SORT = "SET_SORT_TYPE";


