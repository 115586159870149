import {
    ACTION_PERMISSION,
    CREATE_PERMISSION,
    ERROR_CREATE_PERMISSION,
    ACTION_FETCH_PERMISSION,
    FETCH_PERMISSION,
    ERROR_FETCH_PERMISSION,
    CLEAR_PERMISSION,
    UPDATE_PERMISSION,
    ERROR_UPDATE_PERMISSION,
    CLEAR_ALERT_PERMISSION,
    FETCH_PERMISSIONS,
    ERROR_FETCH_PERMISSIONS,
    ACTION_FETCH_PERMISSIONS,
    SET_SELECTED_PAGE_SIZE,
    SET_SEARCH,
    SET_SEARCH_VALUE,
    SET_CURRENT_PAGE,
    SET_SORT,
    DELETE_PERMISSION,
    ERROR_DELETE_PERMISSION
} from './types';



const INIT_STATE = {
    permissions: {
        data: [],
        totalItemCount: 0
    },
    selectedPageSize: 5,
    search: "",
    searchValue: "",
    currentPage: 1,
    sort: [{desc:false, id: "id"}],
    permissionData: {
        permission: ''
    },
    createPermission: '',
    updatePermission: '',
    deletePermission: '',
    loadingBtn: false,
    loading: true,
    error: ''
};


export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case ACTION_PERMISSION:
            return { ...state, loadingBtn: true, error: '' };
        case CREATE_PERMISSION:
            return { ...state, loadingBtn: false, createPermission: action.payload, error: '' };
        case ERROR_CREATE_PERMISSION:
            return { ...state, loadingBtn: false, createPermission: '', error: action.payload.message };
        case ACTION_FETCH_PERMISSION:
            return { ...state, loading: true, error: '' };
        case FETCH_PERMISSION:
            return { ...state, loading: false, permissionData: action.payload, error: '' };
        case ERROR_FETCH_PERMISSION:
            return { ...state, loading: false, permissionData: '', error: action.payload.message };
        case CLEAR_PERMISSION:
            return { ...state, loadingBtn: false, loading: false, permissionData: INIT_STATE.permissionData, error: '' };
        case UPDATE_PERMISSION:
            return { ...state, loadingBtn: false, updatePermission: action.payload, error: '' };
        case ERROR_UPDATE_PERMISSION:
            return { ...state, loadingBtn: false, updatePermission: '', error: action.payload.message };
        case CLEAR_ALERT_PERMISSION:
            return { ...state, updatePermission: '', createPermission: '', deletePermission: '', error: '' };
        case ACTION_FETCH_PERMISSIONS:
            return { ...state, loading: true, error: '' };
        case FETCH_PERMISSIONS:
            return { ...state, loading: false, permissions: action.payload, error: '' };
        case ERROR_FETCH_PERMISSIONS:
            return { ...state, loading: false, permissions: INIT_STATE.permissions, error: action.payload.message };
        case DELETE_PERMISSION:
            return { ...state, loading: false, deletePermission: action.payload, error: '' };
        case ERROR_DELETE_PERMISSION:
            return { ...state, loading: false, deletePermission: '', error: action.payload.message };

        //seting list heading
        case SET_SELECTED_PAGE_SIZE:
            return { ...state, selectedPageSize: action.payload, currentPage: 1 };
        case SET_SEARCH:
            return { ...state, search: action.payload, currentPage: 1 };
        case SET_SEARCH_VALUE:
            return { ...state, searchValue: action.payload };
        case SET_CURRENT_PAGE:
            return { ...state, currentPage: action.payload };
        case SET_SORT:
            return { ...state, sort: action.payload };    
        default: return state;
    }
}
