import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import { createFilter } from 'redux-persist-transform-filter';
import storage from 'redux-persist/lib/storage';

import settings from './settings/reducer';
import menu from './menu/reducer';
import authUser from './auth/reducer';
import product from './products/reducer';
import company from './company/reducer';
import user from './users/reducer';
import permission from './permissions/reducer';
import role from './roles/reducer';
import category from './categories/reducer';
import subcategory from './subcategories/reducer';
import country from './countries/reducer';
import type from './types/reducer';

const persistConfig = {
  key: 'root',
  storage,
  transform: [
    createFilter('authUser', ['author'])
  ]
}


const rootReducer = combineReducers({
  menu,
  settings,
  authUser,
  product,
  company,
  permission,
  role,
  user,
  country,
  category,
  subcategory,
  type
});

export default persistReducer(persistConfig, rootReducer);