/**************** ROLES *********************/

export const ACTION_ROLE = "ACTION_ROLE";
export const CREATE_ROLE = "CREATE_ROLE";
export const ERROR_CREATE_ROLE = "ERROR_CREATE_ROLE";

export const ACTION_FETCH_ROLE = "ACTION_FETCH_ROLE";
export const FETCH_ROLE = "FETCH_ROLE";
export const ERROR_FETCH_ROLE = "ERROR_FETCH_ROLE";
export const CLEAR_ROLE = "CLEAR_ROLE";

export const UPDATE_ROLE = "UPDATE_ROLE";
export const ERROR_UPDATE_ROLE = "ERROR_UPDATE_ROLE";

export const CLEAR_ALERT_ROLE = "CLEAR_ALERT_ROLE";

export const FETCH_ROLES = "FETCH_ROLES";
export const ACTION_FETCH_ROLES = "ACTION_FETCH_ROLES";
export const ERROR_FETCH_ROLES = "ERROR_FETCH_ROLES";

export const DELETE_ROLE = "DELETE_ROLE";
export const ERROR_DELETE_ROLE = "ERROR_DELETE_ROLE";

export const FETCH_PERMISSIONS_TYPES = "FETCH_PERMISSIONS_TYPES";
export const ERROR_FETCH_PERMISSIONS_TYPES = "ERROR_FETCH_PERMISSIONS_TYPES";

export const SET_SELECTED_PAGE_SIZE = "SET_SELECTED_PAGE_SIZE_ROLE";
export const SET_SEARCH = "SET_SEARCH_ROLE";
export const SET_SEARCH_VALUE = "SET_SEARCH_VALUE_ROLE";
export const SET_CURRENT_PAGE = "SET_CURRENT_PAGE_ROLE";
export const SET_SORT = "SET_SORT_ROLE";


