/**************** PERMISSIONS *********************/

export const ACTION_PERMISSION = "ACTION_PERMISSION";
export const CREATE_PERMISSION = "CREATE_PERMISSION";
export const ERROR_CREATE_PERMISSION = "ERROR_CREATE_PERMISSION";

export const ACTION_FETCH_PERMISSION = "ACTION_FETCH_PERMISSION";
export const FETCH_PERMISSION = "FETCH_PERMISSION";
export const ERROR_FETCH_PERMISSION = "ERROR_FETCH_PERMISSION";
export const CLEAR_PERMISSION = "CLEAR_PERMISSION";

export const UPDATE_PERMISSION = "UPDATE_PERMISSION";
export const ERROR_UPDATE_PERMISSION = "ERROR_UPDATE_PERMISSION";

export const CLEAR_ALERT_PERMISSION = "CLEAR_ALERT_PERMISSION";

export const FETCH_PERMISSIONS = "FETCH_PERMISSIONS";
export const ACTION_FETCH_PERMISSIONS = "ACTION_FETCH_PERMISSIONS";
export const ERROR_FETCH_PERMISSIONS = "ERROR_FETCH_PERMISSIONS";

export const DELETE_PERMISSION = "DELETE_PERMISSION";
export const ERROR_DELETE_PERMISSION = "ERROR_DELETE_PERMISSION";

export const SET_SELECTED_PAGE_SIZE = "SET_SELECTED_PAGE_SIZE_PERMISSION";
export const SET_SEARCH = "SET_SEARCH_PERMISSION";
export const SET_SEARCH_VALUE = "SET_SEARCH_VALUE_PERMISSION";
export const SET_CURRENT_PAGE = "SET_CURRENT_PAGE_PERMISSION";
export const SET_SORT = "SET_SORT_PERMISSION";


