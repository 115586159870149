import {
    ACTION_CATEGORY,
    CREATE_CATEGORY,
    ERROR_CREATE_CATEGORY,
    ACTION_FETCH_CATEGORY,
    FETCH_CATEGORY,
    ERROR_FETCH_CATEGORY,
    CLEAR_CATEGORY,
    UPDATE_CATEGORY,
    ERROR_UPDATE_CATEGORY,
    CLEAR_ALERT_CATEGORY,
    FETCH_CATEGORIES,
    ERROR_FETCH_CATEGORIES,
    SET_SELECTED_PAGE_SIZE,
    SET_SEARCH,
    SET_SEARCH_VALUE,
    SET_CURRENT_PAGE,
    SET_SORT,
    DELETE_CATEGORY,
    ERROR_DELETE_CATEGORY,
    ACTIVE_CATEGORY,
    ERROR_ACTIVE_CATEGORY,
    ACTION_DATA_FIELDS_CATEGORY,
    DATA_FIELDS_CATEGORY,
    ERROR_DATA_FIELDS_CATEGORY,
} from './types';



const INIT_STATE = {
    categories: {
        data: [],
        totalItemCount: 0
    },
    selectedPageSize: 5,
    search: "",
    searchValue: "",
    currentPage: 1,
    sort: [{desc:false, id: "id"}],
    createCategory: '',
    updateCategory: '',
    deleteCategory: '',
    categoryData: {
        edit:false,
        category_en: '',
        category_el: '',
        category_de: '',
        category_rs: '',
        category_bg: '',
        category_ru: '',
        company_id: '',
        sort: 0,
        image: '',
        alt_image: '',
        image_name: '',
        file: ''
    },
    dataFields: {
        companies: []
    },
    loadingBtn: false,
    loading: true,
    error: ''
};


export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case ACTION_CATEGORY:
            return { ...state, loadingBtn: true, error: '' };
        case CREATE_CATEGORY:
            return { ...state, loadingBtn: false, createCategory: action.payload, error: '' };
        case ERROR_CREATE_CATEGORY:
            return { ...state, loadingBtn: false, createCategory: '', error: action.payload.message };
        case ACTION_FETCH_CATEGORY:
            return { ...state, loading: true, loadingBtn: false, error: '' };
        case FETCH_CATEGORY:
            return { ...state, loading: false, categoryData: action.payload, error: '' };
        case ERROR_FETCH_CATEGORY:
            return { ...state, loading: false, categoryData: '', error: action.payload.message };
        case CLEAR_CATEGORY:
            return { ...state, loadingBtn: false, loading: false, categoryData: INIT_STATE.categoryData, error: '' };
        case UPDATE_CATEGORY:
            return { ...state, loadingBtn: false, updateCategory: action.payload, error: '' };
        case ERROR_UPDATE_CATEGORY:
            return { ...state, loadingBtn: false, updateCategory: '', error: action.payload.message };
        case CLEAR_ALERT_CATEGORY:
            return { ...state, updateCategory: '', createCategory: '', deleteCategory: '', activeCategory: '', error: '' };
        case FETCH_CATEGORIES:
            return { ...state, loading: false, categories: action.payload, error: '' };
        case ERROR_FETCH_CATEGORIES:
            return { ...state, loading: false, categories: INIT_STATE.categories, error: action.payload.message };
        case DELETE_CATEGORY:
            return { ...state, loading: false, deleteCategory: action.payload, error: '' };
        case ERROR_DELETE_CATEGORY:
            return { ...state, loading: false, deleteCategory: '', error: action.payload.message };
        case ACTIVE_CATEGORY:
            return { ...state, loading: false, activeCategory: action.payload, error: '' };
        case ERROR_ACTIVE_CATEGORY:
            return { ...state, loading: false, activeCategory: '', error: action.payload.message };  
            
        //fetch data for fields
        case ACTION_DATA_FIELDS_CATEGORY:
            return { ...state, loading: true, error: '' };
        case DATA_FIELDS_CATEGORY:
            return { ...state, dataFields: action.payload, error: '' };
        case ERROR_DATA_FIELDS_CATEGORY:
            return { ...state, loading: false, dataFields: INIT_STATE.dataFields, error: action.payload.message };    

        //seting list heading
        case SET_SELECTED_PAGE_SIZE:
            return { ...state, selectedPageSize: action.payload, currentPage: 1 };
        case SET_SEARCH:
            return { ...state, search: action.payload, currentPage: 1 };
        case SET_SEARCH_VALUE:
            return { ...state, searchValue: action.payload };
        case SET_CURRENT_PAGE:
            return { ...state, currentPage: action.payload };
        case SET_SORT:
            return { ...state, sort: action.payload };    
        default: return state;
    }
}
