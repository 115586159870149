import {
    ACTION_PRODUCT,
    CREATE_PRODUCT,
    ERROR_CREATE_PRODUCT,
    ACTION_FETCH_PRODUCT,
    FETCH_PRODUCT,
    ERROR_FETCH_PRODUCT,
    CLEAR_PRODUCT,
    UPDATE_PRODUCT,
    ERROR_UPDATE_PRODUCT,
    CLEAR_ALERT_PRODUCT,
    FETCH_PRODUCTS,
    ERROR_FETCH_PRODUCTS,
    ACTION_FETCH_PRODUCTS,
    SET_DISPLAY_MODE,
    SET_SELECTED_PAGE_SIZE,
    SET_SELECTED_ORDER_OPTION,
    SET_SEARCH,
    SET_SEARCH_VALUE,
    SET_CURRENT_PAGE,
    DELETE_PRODUCT,
    ERROR_DELETE_PRODUCT,
    ACTIVE_PRODUCT,
    ERROR_ACTIVE_PRODUCT,
    ACTION_PRODUCT_INFO,
    PRODUCT_INFO,
    ERROR_PRODUCT_INFO,
    ACTION_DATA_FIELDS_PRODUCT,
    DATA_FIELDS_PRODUCT,
    ERROR_DATA_FIELDS_PRODUCT,
    DATA_SELECT_FIELDS_PRODUCT,
    ERROR_DATA_SELECT_FIELDS_PRODUCT,
    DELETE_PRODUCT_FILE,
    ERROR_DELETE_PRODUCT_FILE
} from './types';



const INIT_STATE = {
    products: {
        data: [],
        totalItemCount: 0
    },
    displayMode: "thumblist",
    selectedPageSize: 10,
    selectedOrderOption: { column: "id-asc", label: "ID ASC" },
    search: "",
    searchValue: "",
    currentPage: 1,
    productData: {
        category_id: '',
        subcategory_id: '',
        company_id: '',
        suggested: 0,
        offer: 0,
        sort: '',

        title_en: '',
        title_el: '',
        title_de: '',
        title_rs: '',
        title_bg: '',
        title_ru: '',

        text_en: '',
        text_el: '',
        text_de: '',
        text_rs: '',
        text_bg: '',
        text_ru: '',

        files: [],
        filesData: [],
        prices:[{title: '', price: '', price_offer: ''}]
    },
    createProduct: '',
    updateProduct: '',
    deleteProduct: '',
    activeProduct: '',
    productInfo: '',
    deleteFile: '',
    dataFields: {
        categories: [],
        companies: []
    },
    dataSelectFields: {
        subcategories: []
    },
    loadingBtn: false,
    loading: true,
    loadingModal: false,
    error: ''
};


export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case ACTION_PRODUCT:
            return { ...state, loadingBtn: true, error: '' };
        case CREATE_PRODUCT:
            return { ...state, loadingBtn: false, createProduct: action.payload, error: '' };
        case ERROR_CREATE_PRODUCT:
            return { ...state, loadingBtn: false, createProduct: '', error: action.payload.message };
        case ACTION_FETCH_PRODUCT:
            return { ...state, loading: true, error: '' };
        case FETCH_PRODUCT:
            return { ...state, loading: false, productData: action.payload, error: '' };
        case ERROR_FETCH_PRODUCT:
            return { ...state, loading: false, productData: '', error: action.payload.message };
        case CLEAR_PRODUCT:
            return { ...state, loadingBtn: false, loading: false, productData: INIT_STATE.productData, error: '' };
        case UPDATE_PRODUCT:
            return { ...state, loadingBtn: false, updateProduct: action.payload, error: '' };
        case ERROR_UPDATE_PRODUCT:
            return { ...state, loadingBtn: false, updateProduct: '', error: action.payload.message };
        case CLEAR_ALERT_PRODUCT:
            return { ...state, updateProduct: '', createProduct: '', deleteProduct: '', activeProduct: '', vatProduct: '', deleteFile: '', error: '' };
        case ACTION_FETCH_PRODUCTS:
            return { ...state, loading: true, error: '' };
        case FETCH_PRODUCTS:
            return { ...state, loading: false, products: action.payload, error: '' };
        case ERROR_FETCH_PRODUCTS:
            return { ...state, loading: false, products: INIT_STATE.products, error: action.payload.message };
        case DELETE_PRODUCT:
            return { ...state, loading: false, deleteProduct: action.payload, error: '' };
        case ERROR_DELETE_PRODUCT:
            return { ...state, loading: false, deleteProduct: '', error: action.payload.message };
        case ACTIVE_PRODUCT:
            return { ...state, loading: false, activeProduct: action.payload, error: '' };
        case ERROR_ACTIVE_PRODUCT:
            return { ...state, loading: false, activeProduct: '', error: action.payload.message };
        case ACTION_PRODUCT_INFO:
            return { ...state, loadingModal: true, error: '' };
        case PRODUCT_INFO:
            return { ...state, loadingModal: false, productInfo: action.payload, error: '' };
        case ERROR_PRODUCT_INFO:
            return { ...state, loadingModal: false, productInfo: '', error: action.payload.message };
        case DELETE_PRODUCT_FILE:
            return { ...state, loading: false, deleteFile: action.payload, error: '' };
        case ERROR_DELETE_PRODUCT_FILE:
            return { ...state, loading: false, deleteFile: '', error: action.payload.message };


        //fetch data for fields
        case ACTION_DATA_FIELDS_PRODUCT:
            return { ...state, loading: true, error: '' };
        case DATA_FIELDS_PRODUCT:
            return { ...state, dataFields: action.payload, error: '' };
        case ERROR_DATA_FIELDS_PRODUCT:
            return { ...state, loading: false, dataFields: INIT_STATE.dataFields, error: action.payload.message };

        //fetch data select for fields
        case DATA_SELECT_FIELDS_PRODUCT:
            return { ...state, dataSelectFields: action.payload, error: '' };
        case ERROR_DATA_SELECT_FIELDS_PRODUCT:
            return { ...state, loading: false, dataSelectFields: INIT_STATE.dataSelectFields, error: action.payload.message };


        //seting list heading
        case SET_DISPLAY_MODE:
            return { ...state, displayMode: action.payload };
        case SET_SELECTED_PAGE_SIZE:
            return { ...state, selectedPageSize: action.payload, currentPage: 1 };
        case SET_SELECTED_ORDER_OPTION:
            return { ...state, selectedOrderOption: action.payload, currentPage: 1 };
        case SET_SEARCH:
            return { ...state, search: action.payload, currentPage: 1 };
        case SET_SEARCH_VALUE:
            return { ...state, searchValue: action.payload };
        case SET_CURRENT_PAGE:
            return { ...state, currentPage: action.payload };
        default: return state;
    }
}
