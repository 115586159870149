import {
    ACTION_TYPE,
    CREATE_TYPE,
    ERROR_CREATE_TYPE,
    ACTION_FETCH_TYPE,
    FETCH_TYPE,
    ERROR_FETCH_TYPE,
    CLEAR_TYPE,
    UPDATE_TYPE,
    ERROR_UPDATE_TYPE,
    CLEAR_ALERT_TYPE,
    FETCH_TYPES,
    ERROR_FETCH_TYPES,
    ACTION_DATA_FIELDS_TYPE,
    DATA_FIELDS_TYPE,
    ERROR_DATA_FIELDS_TYPE,
    SET_SELECTED_PAGE_SIZE,
    SET_SEARCH,
    SET_SEARCH_VALUE,
    SET_CURRENT_PAGE,
    SET_SORT,
    DELETE_TYPE,
    ERROR_DELETE_TYPE,
    ACTIVE_TYPE,
    ERROR_ACTIVE_TYPE
} from './types';



const INIT_STATE = {
    types: {
        data: [],
        totalItemCount: 0
    },
    selectedPageSize: 5,
    search: "",
    searchValue: "",
    currentPage: 1,
    sort: [{ desc: false, id: "id" }],
    createType: '',
    updateType: '',
    deleteType: '',
    typeData: {
        edit: false,
        type: '',
        name_en: '',
        name_el: '',
        desc_en: '',
        desc_el: '',
        color: '',
        icon: '',
        property_category_ids: [],
        query_name: '',
        active_inactive: 1,
        category: ''
    },
    dataFields: {
        property_categories: [],
        activity_categories: []
    },
    loadingBtn: false,
    loading: true,
    error: ''
};


export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case ACTION_TYPE:
            return { ...state, loadingBtn: true, error: '' };
        case CREATE_TYPE:
            return { ...state, loadingBtn: false, createType: action.payload, error: '' };
        case ERROR_CREATE_TYPE:
            return { ...state, loadingBtn: false, createType: '', error: action.payload.message };
        case ACTION_FETCH_TYPE:
            return { ...state, loading: true, loadingBtn: false, error: '' };
        case FETCH_TYPE:
            return { ...state, loading: false, typeData: action.payload, error: '' };
        case ERROR_FETCH_TYPE:
            return { ...state, loading: false, typeData: '', error: action.payload.message };
        case CLEAR_TYPE:
            return { ...state, loadingBtn: false, loading: false, typeData: INIT_STATE.typeData, error: '' };
        case UPDATE_TYPE:
            return { ...state, loadingBtn: false, updateType: action.payload, error: '' };
        case ERROR_UPDATE_TYPE:
            return { ...state, loadingBtn: false, updateType: '', error: action.payload.message };
        case CLEAR_ALERT_TYPE:
            return { ...state, updateType: '', createType: '', deleteType: '', activeType: '', error: '' };
        case FETCH_TYPES:
            return { ...state, loading: false, types: action.payload, error: '' };
        case ERROR_FETCH_TYPES:
            return { ...state, loading: false, types: INIT_STATE.types, error: action.payload.message };
        case DELETE_TYPE:
            return { ...state, loading: false, deleteType: action.payload, error: '' };
        case ERROR_DELETE_TYPE:
            return { ...state, loading: false, deleteType: '', error: action.payload.message };
        case ACTIVE_TYPE:
            return { ...state, loading: false, activeType: action.payload, error: '' };
        case ERROR_ACTIVE_TYPE:
            return { ...state, loading: false, activeType: '', error: action.payload.message };

        //fetch data for fields
        case ACTION_DATA_FIELDS_TYPE:
            return { ...state, loading: true, error: '' };
        case DATA_FIELDS_TYPE:
            return { ...state, dataFields: action.payload, error: '' };
        case ERROR_DATA_FIELDS_TYPE:
            return { ...state, loading: false, dataFields: INIT_STATE.dataFields, error: action.payload.message };

        //seting list heading
        case SET_SELECTED_PAGE_SIZE:
            return { ...state, selectedPageSize: action.payload, currentPage: 1 };
        case SET_SEARCH:
            return { ...state, search: action.payload, currentPage: 1 };
        case SET_SEARCH_VALUE:
            return { ...state, searchValue: action.payload };
        case SET_CURRENT_PAGE:
            return { ...state, currentPage: action.payload };
        case SET_SORT:
            return { ...state, sort: action.payload };
        default: return state;
    }
}
