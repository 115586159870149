import {
    ACTION_ROLE,
    CREATE_ROLE,
    ERROR_CREATE_ROLE,
    ACTION_FETCH_ROLE,
    FETCH_ROLE,
    ERROR_FETCH_ROLE,
    CLEAR_ROLE,
    UPDATE_ROLE,
    ERROR_UPDATE_ROLE,
    CLEAR_ALERT_ROLE,
    FETCH_ROLES,
    ERROR_FETCH_ROLES,
    SET_SELECTED_PAGE_SIZE,
    SET_SEARCH,
    SET_SEARCH_VALUE,
    SET_CURRENT_PAGE,
    SET_SORT,
    DELETE_ROLE,
    ERROR_DELETE_ROLE,
    FETCH_PERMISSIONS_TYPES,
    ERROR_FETCH_PERMISSIONS_TYPES
} from './types';



const INIT_STATE = {
    roles: {
        data: [],
        totalItemCount: 0
    },
    selectedPageSize: 5,
    search: "",
    searchValue: "",
    currentPage: 1,
    sort: [{desc:false, id: "id"}],
    permissionsTypes: [],
    roleData: {
        role: '',
        permissions: [],
        contacts_list: 0,
        properties_list: 0,
        assignments_list: 0,
        requests_list: 0,
        requests_bucket_list: 0
    },
    createRole: '',
    updateRole: '',
    deleteRole: '',
    loadingBtn: false,
    loading: true,
    error: ''
};


export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case ACTION_ROLE:
            return { ...state, loadingBtn: true, error: '' };
        case CREATE_ROLE:
            return { ...state, loadingBtn: false, createRole: action.payload, error: '' };
        case ERROR_CREATE_ROLE:
            return { ...state, loadingBtn: false, createRole: '', error: action.payload.message };
        case ACTION_FETCH_ROLE:
            return { ...state, loading: true, error: '' };
        case FETCH_ROLE:
            return { ...state, loading: false, roleData: action.payload, error: '' };
        case ERROR_FETCH_ROLE:
            return { ...state, loading: false, roleData: '', error: action.payload.message };
        case CLEAR_ROLE:
            return { ...state, loadingBtn: false, loading: false, roleData: INIT_STATE.roleData, error: '' };
        case UPDATE_ROLE:
            return { ...state, loadingBtn: false, updateRole: action.payload, error: '' };
        case ERROR_UPDATE_ROLE:
            return { ...state, loadingBtn: false, updateRole: '', error: action.payload.message };
        case CLEAR_ALERT_ROLE:
            return { ...state, updateRole: '', createRole: '', deleteRole: '', error: '' };
        case FETCH_ROLES:
            return { ...state, loading: false, roles: action.payload, error: '' };
        case ERROR_FETCH_ROLES:
            return { ...state, loading: false, roles: INIT_STATE.roles, error: action.payload.message };
        case DELETE_ROLE:
            return { ...state, loading: false, deleteRole: action.payload, error: '' };
        case ERROR_DELETE_ROLE:
            return { ...state, loading: false, deleteRole: '', error: action.payload.message };

        //fetch categories
        case FETCH_PERMISSIONS_TYPES:
            return { ...state, permissionsTypes: action.payload, error: '' };
        case ERROR_FETCH_PERMISSIONS_TYPES:
            return { ...state, permissionsTypes: '', error: action.payload.message };

        //seting list heading
        case SET_SELECTED_PAGE_SIZE:
            return { ...state, selectedPageSize: action.payload, currentPage: 1 };
        case SET_SEARCH:
            return { ...state, search: action.payload, currentPage: 1 };
        case SET_SEARCH_VALUE:
            return { ...state, searchValue: action.payload };
        case SET_CURRENT_PAGE:
            return { ...state, currentPage: action.payload };
        case SET_SORT:
            return { ...state, sort: action.payload };    
        default: return state;
    }
}
