/**************** COMPANY *********************/
export const ACTION_COMPANY = "ACTION_COMPANY";
export const CREATE_COMPANY = "CREATE_COMPANY";
export const ERROR_CREATE_COMPANY = "ERROR_CREATE_COMPANY";

export const ACTION_FETCH_COMPANY = "ACTION_FETCH_COMPANY";
export const FETCH_COMPANY = "FETCH_COMPANY";
export const ERROR_FETCH_COMPANY = "ERROR_FETCH_COMPANY";
export const CLEAR_COMPANY = "CLEAR_COMPANY";

export const UPDATE_COMPANY = "UPDATE_COMPANY";
export const ERROR_UPDATE_COMPANY = "ERROR_UPDATE_COMPANY";

export const CLEAR_ALERT_COMPANY = "CLEAR_ALERT_COMPANY";

export const FETCH_COMPANIES = "FETCH_COMPANIES";
export const ACTION_FETCH_COMPANIES = "ACTION_FETCH_COMPANIES";
export const ERROR_FETCH_COMPANIES = "ERROR_FETCH_COMPANIES";

export const DELETE_COMPANY = "DELETE_COMPANY";
export const ERROR_DELETE_COMPANY = "ERROR_DELETE_COMPANY";

export const ACTIVE_COMPANY = "ACTIVE_COMPANY";
export const ERROR_ACTIVE_COMPANY = "ERROR_ACTIVE_COMPANY";

export const VAT_COMPANY = "VAT_COMPANY";
export const ERROR_VAT_COMPANY = "ERROR_VAT_COMPANY";

export const ACTION_COMPANY_INFO = "ACTION_COMPANY_INFO";
export const COMPANY_INFO = "COMPANY_INFO";
export const ERROR_COMPANY_INFO = "ERROR_COMPANY_INFO";

export const ACTION_DATA_FIELDS_COMPANY = "ACTION_DATA_FIELDS_COMPANY";
export const DATA_FIELDS_COMPANY = "DATA_FIELDS_COMPANY";
export const ERROR_DATA_FIELDS_COMPANY = "ERROR_DATA_FIELDS_COMPANY";

export const SET_DISPLAY_MODE = "SET_DISPLAY_MODE";
export const SET_SELECTED_PAGE_SIZE = "SET_SELECTED_PAGE_SIZE";
export const SET_SELECTED_ORDER_OPTION = "SET_SELECTED_ORDER_OPTION";
export const SET_SEARCH = "SET_SEARCH";
export const SET_SEARCH_VALUE = "SET_SEARCH_VALUE";
export const SET_CURRENT_PAGE = "SET_CURRENT_PAGE";


