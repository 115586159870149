/* 
JWT Auth Token for connected to the api
*/
export const jwt_secret = '3JYvtzbCwsyChGJ4DkoFhhTLewDeiEPY8NxwrE6Pb1VvLoJoQfL7Hrat9ytgqgm3';

/* 
Google API KEY
*/
export const google_key = 'AIzaSyC0XoOZWw-3_GWJGKWXHfIztsV61qszoOc';

/* 
Base urls for api and sites
*/
export const BASEURLAPI = "https://catapi.humantwo.gr/api/data";
export const BASEURL = "https://app.humantwo.gr";
export const BASEURLAPIIMAGES = "https://catapi.humantwo.gr/public/images";


//AUTH URL
export const AUTH = `${BASEURLAPI}/auth`;

//PRODUCTS URL
export const PRODUCT = `${BASEURLAPI}/products`;

//COMPANIES URL
export const COMPANY = `${BASEURLAPI}/companies`;

//USER URL
export const USER = `${BASEURLAPI}/users`;

//PERMISSIONS URL
export const PERMISSIONS = `${BASEURLAPI}/permissions`;

//ROLES URL
export const ROLES = `${BASEURLAPI}/roles`;

/************** START APP SETTINGS URLS ******************/

//CATEGORIES URL
export const CATEGORIES = `${BASEURLAPI}/categories`;

//SUBCATEGORIES URL
export const SUBCATEGORIES = `${BASEURLAPI}/subcategories`;

//COUNTRIES URL
export const COUNTRIES = `${BASEURLAPI}/countries`;

//TYPES URL
export const TYPES = `${BASEURLAPI}/types`;

/************** END APP SETTINGS URLS ******************/

//SOCIAL NETWORKS
export const FACEBOOK = `https://www.facebook.com`;
export const INSTAGRAM = `https://www.instagram.com`;
export const TWITTER = `https://twitter.com`;

//Headers for applications requests
export const HEADERS = {
  //'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*',
  //'Accept':'application/json',
};

/* 
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = "menu-default";

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = "en";
export const localeOptions = [
  { id: "en", name: "English", direction: "ltr" },
  { id: "el", name: "Ελληνικά", direction: "ltr" },
];

export const searchPath = "/app/pages/search";
export const servicePath = "https://api.coloredstrategies.com";

/* 
Color Options:
"light.purple", "light.blue", "light.green", "light.orange", "light.red", "dark.purple", "dark.blue", "dark.green", "dark.orange", "dark.red"
*/
export const themeColorStorageKey = "__theme_color"
export const isMultiColorActive = true;
export const isDarkSwitchActive = true;
export const defaultColor = "light.blue";
export const defaultDirection = "ltr";
export const themeRadiusStorageKey = "__theme_radius";
export const isDemo = false;