/**************** PRODUCT *********************/
export const ACTION_PRODUCT = "ACTION_PRODUCT";
export const CREATE_PRODUCT = "CREATE_PRODUCT";
export const ERROR_CREATE_PRODUCT = "ERROR_CREATE_PRODUCT";

export const ACTION_FETCH_PRODUCT = "ACTION_FETCH_PRODUCT";
export const FETCH_PRODUCT = "FETCH_PRODUCT";
export const ERROR_FETCH_PRODUCT = "ERROR_FETCH_PRODUCT";
export const CLEAR_PRODUCT = "CLEAR_PRODUCT";

export const UPDATE_PRODUCT = "UPDATE_PRODUCT";
export const ERROR_UPDATE_PRODUCT = "ERROR_UPDATE_PRODUCT";

export const CLEAR_ALERT_PRODUCT = "CLEAR_ALERT_PRODUCT";

export const FETCH_PRODUCTS = "FETCH_PRODUCTS";
export const ACTION_FETCH_PRODUCTS = "ACTION_FETCH_PRODUCTS";
export const ERROR_FETCH_PRODUCTS = "ERROR_FETCH_PRODUCTS";

export const DELETE_PRODUCT = "DELETE_PRODUCT";
export const ERROR_DELETE_PRODUCT = "ERROR_DELETE_PRODUCT";

export const ACTIVE_PRODUCT = "ACTIVE_PRODUCT";
export const ERROR_ACTIVE_PRODUCT = "ERROR_ACTIVE_PRODUCT";

export const ACTION_PRODUCT_INFO = "ACTION_PRODUCT_INFO";
export const PRODUCT_INFO = "PRODUCT_INFO";
export const ERROR_PRODUCT_INFO = "ERROR_PRODUCT_INFO";

export const ACTION_DATA_FIELDS_PRODUCT = "ACTION_DATA_FIELDS_PRODUCT";
export const DATA_FIELDS_PRODUCT = "DATA_FIELDS_PRODUCT";
export const ERROR_DATA_FIELDS_PRODUCT = "ERROR_DATA_FIELDS_PRODUCT";

export const DATA_SELECT_FIELDS_PRODUCT = "DATA_SELECT_FIELDS_PRODUCT";
export const ERROR_DATA_SELECT_FIELDS_PRODUCT = "ERROR_DATA_SELECT_FIELDS_PRODUCT";

export const DELETE_PRODUCT_FILE = "DELETE_PRODUCT_FILE";
export const ERROR_DELETE_PRODUCT_FILE = "ERROR_DELETE_PRODUCT_FILE";

export const SET_DISPLAY_MODE = "SET_DISPLAY_MODE";
export const SET_SELECTED_PAGE_SIZE = "SET_SELECTED_PAGE_SIZE";
export const SET_SELECTED_ORDER_OPTION = "SET_SELECTED_ORDER_OPTION";
export const SET_SEARCH = "SET_SEARCH";
export const SET_SEARCH_VALUE = "SET_SEARCH_VALUE";
export const SET_CURRENT_PAGE = "SET_CURRENT_PAGE";


