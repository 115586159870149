import {
    ACTION_SUBCATEGORY,
    CREATE_SUBCATEGORY,
    ERROR_CREATE_SUBCATEGORY,
    ACTION_FETCH_SUBCATEGORY,
    FETCH_SUBCATEGORY,
    ERROR_FETCH_SUBCATEGORY,
    CLEAR_SUBCATEGORY,
    UPDATE_SUBCATEGORY,
    ERROR_UPDATE_SUBCATEGORY,
    CLEAR_ALERT_SUBCATEGORY,
    FETCH_SUBCATEGORIES,
    ERROR_FETCH_SUBCATEGORIES,
    SET_SELECTED_PAGE_SIZE,
    SET_SEARCH,
    SET_SEARCH_VALUE,
    SET_CURRENT_PAGE,
    SET_SORT,
    DELETE_SUBCATEGORY,
    ERROR_DELETE_SUBCATEGORY,
    ACTIVE_SUBCATEGORY,
    ERROR_ACTIVE_SUBCATEGORY,
    ACTION_DATA_FIELDS_SUBCATEGORY,
    DATA_FIELDS_SUBCATEGORY,
    ERROR_DATA_FIELDS_SUBCATEGORY,
} from './types';



const INIT_STATE = {
    subcategories: {
        data: [],
        totalItemCount: 0
    },
    selectedPageSize: 5,
    search: "",
    searchValue: "",
    currentPage: 1,
    sort: [{desc:false, id: "id"}],
    createSubcategory: '',
    updateSubcategory: '',
    deleteSubcategory: '',
    subcategoryData: {
        edit:false,
        subcategory_en: '',
        subcategory_el: '',
        subcategory_de: '',
        subcategory_rs: '',
        subcategory_bg: '',
        subcategory_ru: '',
        category_id: '',
        company_id: '',
        sort: 0
    },
    dataFields: {
        categories: [],
        companies: []
    },
    loadingBtn: false,
    loading: true,
    error: ''
};


export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case ACTION_SUBCATEGORY:
            return { ...state, loadingBtn: true, error: '' };
        case CREATE_SUBCATEGORY:
            return { ...state, loadingBtn: false, createSubcategory: action.payload, error: '' };
        case ERROR_CREATE_SUBCATEGORY:
            return { ...state, loadingBtn: false, createSubcategory: '', error: action.payload.message };
        case ACTION_FETCH_SUBCATEGORY:
            return { ...state, loading: true, loadingBtn: false, error: '' };
        case FETCH_SUBCATEGORY:
            return { ...state, loading: false, subcategoryData: action.payload, error: '' };
        case ERROR_FETCH_SUBCATEGORY:
            return { ...state, loading: false, subcategoryData: '', error: action.payload.message };
        case CLEAR_SUBCATEGORY:
            return { ...state, loadingBtn: false, loading: false, subcategoryData: INIT_STATE.subcategoryData, error: '' };
        case UPDATE_SUBCATEGORY:
            return { ...state, loadingBtn: false, updateSubcategory: action.payload, error: '' };
        case ERROR_UPDATE_SUBCATEGORY:
            return { ...state, loadingBtn: false, updateSubcategory: '', error: action.payload.message };
        case CLEAR_ALERT_SUBCATEGORY:
            return { ...state, updateSubcategory: '', createSubcategory: '', deleteSubcategory: '', activeSubcategory: '', error: '' };
        case FETCH_SUBCATEGORIES:
            return { ...state, loading: false, subcategories: action.payload, error: '' };
        case ERROR_FETCH_SUBCATEGORIES:
            return { ...state, loading: false, subcategories: INIT_STATE.subcategories, error: action.payload.message };
        case DELETE_SUBCATEGORY:
            return { ...state, loading: false, deleteSubcategory: action.payload, error: '' };
        case ERROR_DELETE_SUBCATEGORY:
            return { ...state, loading: false, deleteSubcategory: '', error: action.payload.message };
        case ACTIVE_SUBCATEGORY:
            return { ...state, loading: false, activeSubcategory: action.payload, error: '' };
        case ERROR_ACTIVE_SUBCATEGORY:
            return { ...state, loading: false, activeSubcategory: '', error: action.payload.message }; 
        
        //fetch data for fields
        case ACTION_DATA_FIELDS_SUBCATEGORY:
            return { ...state, loading: true, error: '' };
        case DATA_FIELDS_SUBCATEGORY:
            return { ...state, dataFields: action.payload, error: '' };
        case ERROR_DATA_FIELDS_SUBCATEGORY:
            return { ...state, loading: false, dataFields: INIT_STATE.dataFields, error: action.payload.message };    

        //seting list heading
        case SET_SELECTED_PAGE_SIZE:
            return { ...state, selectedPageSize: action.payload, currentPage: 1 };
        case SET_SEARCH:
            return { ...state, search: action.payload, currentPage: 1 };
        case SET_SEARCH_VALUE:
            return { ...state, searchValue: action.payload };
        case SET_CURRENT_PAGE:
            return { ...state, currentPage: action.payload };
        case SET_SORT:
            return { ...state, sort: action.payload };    
        default: return state;
    }
}
