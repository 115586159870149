import {
    ACTION_COMPANY,
    CREATE_COMPANY,
    ERROR_CREATE_COMPANY,
    ACTION_FETCH_COMPANY,
    FETCH_COMPANY,
    ERROR_FETCH_COMPANY,
    CLEAR_COMPANY,
    UPDATE_COMPANY,
    ERROR_UPDATE_COMPANY,
    CLEAR_ALERT_COMPANY,
    FETCH_COMPANIES,
    ERROR_FETCH_COMPANIES,
    ACTION_FETCH_COMPANIES,
    SET_DISPLAY_MODE,
    SET_SELECTED_PAGE_SIZE,
    SET_SELECTED_ORDER_OPTION,
    SET_SEARCH,
    SET_SEARCH_VALUE,
    SET_CURRENT_PAGE,
    DELETE_COMPANY,
    ERROR_DELETE_COMPANY,
    ACTIVE_COMPANY,
    ERROR_ACTIVE_COMPANY,
    VAT_COMPANY,
    ERROR_VAT_COMPANY,
    ACTION_COMPANY_INFO,
    COMPANY_INFO,
    ERROR_COMPANY_INFO,
    ACTION_DATA_FIELDS_COMPANY,
    DATA_FIELDS_COMPANY,
    ERROR_DATA_FIELDS_COMPANY,
} from './types';



const INIT_STATE = {
    companies: {
        data: [],
        totalItemCount: 0
    },
    displayMode: "thumblist",
    selectedPageSize: 10,
    selectedOrderOption: { column: "id-asc", label: "ID ASC" },
    search: "",
    searchValue: "",
    currentPage: 1,
    companyData: {
        business_name: '',
        phone: '',
        email: '',
        wifi: '',
        vat: '',
        validateVat: 0,
        address: '',
        country: '',
        iso: '',
        code: '',
        web: '',
        image: '',
        file: '',
        notes: '',
        description: '',
        lat: '',
        lng: '',
        fb: '',
        insta: '',
        languages: [{language_id: '', master: 1}],
        show_title: 0,
        credit_card: 0,
        cash: 0
    },
    createCompany: '',
    updateCompany: '',
    deleteCompany: '',
    activeCompany: '',
    vatCompany: '',
    companyInfo: '',
    dataFields: {
        countries: [],
        languages: []
    },
    loadingBtn: false,
    loading: true,
    loadingModal: false,
    error: ''
};


export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case ACTION_COMPANY:
            return { ...state, loadingBtn: true, error: '' };
        case CREATE_COMPANY:
            return { ...state, loadingBtn: false, createCompany: action.payload, error: '' };
        case ERROR_CREATE_COMPANY:
            return { ...state, loadingBtn: false, createCompany: '', error: action.payload.message };
        case ACTION_FETCH_COMPANY:
            return { ...state, loading: true, error: '' };
        case FETCH_COMPANY:
            return { ...state, loading: false, companyData: action.payload, error: '' };
        case ERROR_FETCH_COMPANY:
            return { ...state, loading: false, companyData: '', error: action.payload.message };
        case CLEAR_COMPANY:
            return { ...state, loadingBtn: false, loading: false, companyData: INIT_STATE.companyData, error: '' };
        case UPDATE_COMPANY:
            return { ...state, loadingBtn: false, updateCompany: action.payload, error: '' };
        case ERROR_UPDATE_COMPANY:
            return { ...state, loadingBtn: false, updateCompany: '', error: action.payload.message };
        case CLEAR_ALERT_COMPANY:
            return { ...state, updateCompany: '', createCompany: '', deleteCompany: '', activeCompany: '', vatCompany: '', error: '' };
        case ACTION_FETCH_COMPANIES:
            return { ...state, loading: true, error: '' };
        case FETCH_COMPANIES:
            return { ...state, loading: false, companies: action.payload, error: '' };
        case ERROR_FETCH_COMPANIES:
            return { ...state, loading: false, companies: INIT_STATE.companies, error: action.payload.message };
        case DELETE_COMPANY:
            return { ...state, loading: false, deleteCompany: action.payload, error: '' };
        case ERROR_DELETE_COMPANY:
            return { ...state, loading: false, deleteCompany: '', error: action.payload.message };
        case ACTIVE_COMPANY:
            return { ...state, loading: false, activeCompany: action.payload, error: '' };
        case ERROR_ACTIVE_COMPANY:
            return { ...state, loading: false, activeCompany: '', error: action.payload.message };
        case VAT_COMPANY:
            return { ...state, loading: false, vatCompany: action.payload, error: '' };
        case ERROR_VAT_COMPANY:
            return { ...state, loading: false, vatCompany: '', error: action.payload.message };    
        case ACTION_COMPANY_INFO:
            return { ...state, loadingModal: true, error: '' };
        case COMPANY_INFO:
            return { ...state, loadingModal: false, companyInfo: action.payload, error: '' };
        case ERROR_COMPANY_INFO:
            return { ...state, loadingModal: false, companyInfo: '', error: action.payload.message };


        //fetch data for fields
        case ACTION_DATA_FIELDS_COMPANY:
            return { ...state, loading: true, error: '' };
        case DATA_FIELDS_COMPANY:
            return { ...state, dataFields: action.payload, error: '' };
        case ERROR_DATA_FIELDS_COMPANY:
            return { ...state, loading: false, dataFields: INIT_STATE.dataFields, error: action.payload.message };


        //seting list heading
        case SET_DISPLAY_MODE:
            return { ...state, displayMode: action.payload };
        case SET_SELECTED_PAGE_SIZE:
            return { ...state, selectedPageSize: action.payload, currentPage: 1 };
        case SET_SELECTED_ORDER_OPTION:
            return { ...state, selectedOrderOption: action.payload, currentPage: 1 };
        case SET_SEARCH:
            return { ...state, search: action.payload, currentPage: 1 };
        case SET_SEARCH_VALUE:
            return { ...state, searchValue: action.payload };
        case SET_CURRENT_PAGE:
            return { ...state, currentPage: action.payload };
        default: return state;
    }
}
